import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "../App.scss";
import AppRoutes from "../../routes/Web";
import Navbar from "../navbar/navbar";
import NavbarAdmin from "../navbar/navbarAdmin";
import Sidebar from "../sidebar/sidebarbacklup";
import FooterUser from "../footer/footerUser";
import AuthService from "../../services/auth.service.js";
import "react-notifications/lib/notifications.css";
import SidebarAdmin from "../sidebar/sidebarAdmin";
import './image.css'

class App extends Component {

  state = {
    adminArea: '',
  };


  componentDidMount() {


    this.onRouteChanged()


  }

  handleRefresh() {
    AuthService.refresh()
      .then((res) => {
        clearInterval(this.stopWatchID);
        this.refreshToken();
      })
      .catch((err) => {
        console.log(err);
      });
  }


  handleRefreshAdmin() {
    AuthService.refreshAdmin()
      .then((res) => {
        clearInterval(this.stopWatchIDadmin);
        this.refreshToken();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {

    let navbarComponent = !this.state.isFullPageLayout ? this.state.adminArea ? <NavbarAdmin /> : <Navbar /> : "";
    let sidebarComponent = !this.state.isFullPageLayout ? this.state.adminArea ? <SidebarAdmin /> : <Sidebar /> : "";
    let footerComponent = !this.state.isFullPageLayout ? <FooterUser /> : "";
    return (
      <>
        {this.state.adminArea ?
          <div className="container-scroller">
            {this.state.adminArea === true ? <>{sidebarComponent}</> : <></>}
            <div
              className="container-fluid page-body-wrapper"
              style={{
                position: "relative",
                display: "flex",
                height: "100vh",
                overflow: "scroll",
              }}
            >
              {navbarComponent}
              <div className="main-panel">
                <div className="content-wrapper ">
                  <AppRoutes />
                </div>
              </div>
            </div>
          </div>

          :

          <>
            {navbarComponent}
            <div className="container page-body-wrapper">
              <div className="main-panel mb-5">
                <div className="content-wrapper-user">
                  <AppRoutes />
                </div>
                {footerComponent}
              </div>
            </div>
          </>


        }


      </>
    );
  }

  componentDidUpdate(prevProps) {
    clearInterval(this.stopWatchID);
    clearInterval(this.stopWatchIDadmin);
    this.refreshToken();
    this.refreshTokenAdmin();
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  // ngerefresh token biar ga expired
  refreshToken() {
    if (localStorage.getItem("user")) {
      this.stopWatchID = setInterval(() => {
        if (!localStorage.getItem("user")) {
          clearInterval(this.stopWatchID);
        } else {
          const expiration = new Date(
            JSON.parse(atob(localStorage.getItem("user").split(".")[1])).exp
          );
          const now = new Date();
          const valid = expiration.getTime() * 1000 - now.getTime();
          //300000 5 menit
          if (valid < 300000) {
            this.handleRefresh();
          }
        }
      }, 1000);
    }
  }

  refreshTokenAdmin() {
    if (localStorage.getItem("admin")) {
      this.stopWatchIDadmin = setInterval(() => {
        if (!localStorage.getItem("admin")) {
          clearInterval(this.stopWatchIDadmin);
        } else {
          const expiration = new Date(
            JSON.parse(atob(localStorage.getItem("admin").split(".")[1])).exp
          );
          const nowadmin = new Date();
          const validadmin = expiration.getTime() * 1000 - nowadmin.getTime();
          //300000 5 menit
          if (validadmin < 300000) {
            this.handleRefreshAdmin();
          }
        }
      }, 1000);
    }
  }

  onRouteChanged() {

    if (window.location.pathname.split('/')[1] === "My4RBI4DM") {


      this.setState({
        adminArea: true,
      }, () => {
        if (window.location.pathname === "/My4RBI4DM/login") {
          document
            .querySelector(".page-body-wrapper")
            .classList.add("full-page-wrapper");
        }
      })

    } else {

      this.setState({
        adminArea: false,
      })
    }



    const body = document.querySelector("body");
    if (this.props.location.pathname === "/layout/RtlLayout") {
      body.classList.add("rtl");
    } else {
      body.classList.remove("rtl");
    }
    // window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ["/login", "/register", "/terms/conditon", "/My4RBI4DM/login"];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {

      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        body.classList.remove("imagebg");
        this.setState({
          isFullPageLayout: true,
        });

        // console.log(" ====== ROUTE CHANGED ======");





        if (!this.state.adminArea) {
          // document
          // .querySelector(".content-wrapper")
          // .classList.add("");


          document
            .querySelector(".page-body-wrapper")
            .classList.add("full-page-wrapper");

          try {
            document
              .querySelector(".container")
              .classList.remove("container");
          } catch (error) {

          }

        }

        break;
      } else {

        body.classList.add("imagebg");
        document
          .querySelector(".page-body-wrapper")
          .classList.remove("full-page-wrapper");


        if (!this.state.adminArea) {
          document
            .querySelector(".page-body-wrapper")
            .classList.add("container");


        }


        try {
          document
            .querySelector(".content-wrapper-user")
            .classList.remove("content-auth");
        } catch (error) {

        }


        this.setState({
          isFullPageLayout: false,
        });

      }
    }




  }
}

export default withRouter(App);
