import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicAdmin = ({ component: Component, ...rest }) => {

    function hasJWT() {
      
        let flag = false;
        localStorage.getItem("admin") ? flag = true : flag = false
        return flag

    }

    return (
        <Route {...rest}
            render={props => (
                hasJWT() ?
                    <Redirect to={{ pathname: '/My4RBI4DM/dashboard' }} />
                    :
                    <Component {...props} />
            )}
        />
    );
};

export default PublicAdmin;
