import authHeader from './auth-header';

class UserService {
  getPublicContent() {
    return authHeader.get(process.env.REACT_APP_BASE_URL + 'all');
  }

  getUserBoard() {
    return authHeader.get(process.env.REACT_APP_BASE_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return authHeader.get(process.env.REACT_APP_BASE_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return authHeader.get(process.env.REACT_APP_BASE_URL + 'admin', { headers: authHeader() });
  }

  validToken(){
      return authHeader().post(process.env.REACT_APP_BASE_URL + '/me');
  }
}

export default new UserService();