import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
// import { Col, Collapse, Dropdown } from 'react-bootstrap';
import { Collapse } from "react-bootstrap";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/transaction", state: "allTranscationMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    return (
      <nav
        className="sidebar sidebar-offcanvas"
        id="sidebar"
        style={{ height: "100vh", overflow: "hidden scroll" }}
      >
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <Link to={"/dashboard"} className="sidebar-brand brand-logo">
            {" "}
            <img src={require("../../assets/images/logo-lg.png")} alt="logo" />
          </Link>
          <Link to={"/dashboard"} className="sidebar-brand brand-logo-mini">
            {" "}
            <img src={require("../../assets/images/logo-sm.png")} alt="logo" />
          </Link>
        </div>
        <ul className="nav">
          <li className="nav-item nav-category">
            <span className="nav-link">Main Menu</span>
          </li>
          <li
            className={
              this.isPathActive("/dashboard")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>

          <li className="nav-item nav-category">
            <span className="nav-link">Bot Setup </span>
          </li>

          <li
            className={
              this.isPathActive("/screening")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/screening">
              <span className="menu-icon">
                <i className="mdi mdi-fullscreen-exit"></i>
              </span>
              <span className="menu-title">Sreening</span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/package")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/package">
              <span className="menu-icon">
                <i className="mdi mdi-package-variant-closed"></i>
              </span>
              <span className="menu-title">Bot Package</span>
            </Link>
          </li>


          <li
            className={
              this.isPathActive("/setup")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/setup">
              <span className="menu-icon">
                <i className="mdi mdi-settings"></i>
              </span>
              <span className="menu-title">Bot Setup</span>
            </Link>
          </li>



          <li className="nav-item nav-category">
            <span className="nav-link">Transaction</span>
          </li>

          <li
            className={
              this.isPathActive("/transaction")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                this.state.allTranscationMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("allTranscationMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-book-open"></i>
              </span>
              <span className="menu-title">Transaction</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.allTranscationMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/transaction/dex-trade")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/transaction/dex-trade"
                    >
                      Transaction DexTrade
                    </Link>
                  </li>


                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/transaction/referral")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/transaction/referral"
                    >
                      Referral Transaction
                    </Link>
                  </li>


                  <li className="nav-item">
                    <Link
                      className={
                        this.isPathActive("/transaction/history")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/transaction/history"
                    >
                      Transaction History
                    </Link>
                  </li>

                </ul>
              </div>
            </Collapse>
          </li>

          {/* admin activity */}
          {/* <li
            className={
              this.isPathActive("/admin-activity")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/admin-activity">
              <span className="menu-icon">
                <i className="mdi mdi-desktop-classic"></i>
              </span>
              <span className="menu-title">Admin Activity</span>
            </Link>
          </li>  */}
          {/* admin activity */}
          {/* Game Infomation */}
          {/* <li className="nav-item nav-category">
            <span className="nav-link">Games Information</span>
          </li> */}
          {/* Game Infomation */}
          {/* <li
            className={
              this.isPathActive("/game-list")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            
            <Link className="nav-link" to="/game-list">
              <span className="menu-icon">
                <i className="mdi mdi-gamepad-variant-outline"></i>
              </span>
              <span className="menu-title">Games List</span>
            </Link>
          </li> */}



        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
