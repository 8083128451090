import authHeaderAdmin from "../../auth-header-admin";

class profileModel {

    me() {
        return authHeaderAdmin().post(process.env.REACT_APP_BASE_URL + '/admin/me')
    }

}

export default new profileModel();
