import React from 'react';
import { Route, Redirect } from 'react-router-dom';
 
const PublicGuard = ({ component: Component, ...rest }) => {
 
   function hasJWT() {
       let flag = false;
       localStorage.getItem("user") ? flag=true : flag=false
       return flag
   }
 
   return (
       <Route {...rest}
           render={props => (
               hasJWT() ?
                   <Redirect to={{ pathname: '/dashboard' }} />
                   :
                   <Component {...props} />
           )}
       />
   );
};
 
export default PublicGuard;
