import React, { Component, Suspense, lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import RouteGuard from "./RouteGuard"
import PublicGuard from "./PublicGuard"
import AdminGuard from "./AdminGuard"
import PublicAdmin from './PublicAdmin';
import Spinner from '../layout/spinner/Spinner';


const Dashboard = lazy(() => import('../pages/dashboard/Dashboard'));


// const Error404 = lazy(() => import('../error-pages/Error404'));
// const Error500 = lazy(() => import('../error-pages/Error500'));

const Login = lazy(() => import('../pages/login/Login'));
const Register = lazy(() => import('../pages/register/Register'));
const TermsCondition = lazy(() => import('../pages/termscondition/TermCondition'));
const Screening = lazy(() => import('../pages/screening/Screening'));
const BotPackage = lazy(() => import('../pages/botpackage/BotPackage'));
const BotSetup = lazy(() => import('../pages/botsetup/BotSetup'));
const TransactionDexTrade = lazy(() => import('../pages/transaction/DexTrade'));
const TransactionReferral = lazy(() => import('../pages/transaction/Referral'));
const TransactionHistory = lazy(() => import('../pages/transaction/History'));
const Profile = lazy(() => import('../pages/profile/Profile'));
const Test = lazy(() => import('../pages/test/Test'));
const Password = lazy(() => import('../pages/password/Password'));
const Wallet = lazy(() => import('../pages/wallet/Wallet'));
const WalletHistory = lazy(() => import('../pages/wallet/WalletHistory'));


// const Notfound = lazy(() => import('../pages/notfound/Notfound'));

// const Register1 = lazy(() => import('../user-pages/Register'));


// admin
const LoginAdmin = lazy(() => import('../pages/Admin/login/Login'));
const DashboardAdmin = lazy(() => import('../pages/Admin/dashboard/Dashboard'));
const ManageUserAdmin = lazy(() => import('../pages/Admin/user/ManageUser'));
const SwapHistory = lazy(() => import('../pages/Admin/Swap/SwapHistory'));
const SendHistory = lazy(() => import('../pages/Admin/Send/SendHistory'));


const BotPackageAdmin = lazy(() => import('../pages/Admin/botpackage/BotPackage'));
const VoucherAdmin = lazy(() => import('../pages/Admin/voucher/Voucher'));
const ManageTokenAdmin = lazy(() => import('../pages/Admin/token/ManageToken'));
const OpenTrade = lazy(() => import('../pages/Admin/opentrade/OpenTrade'));
// penambahan group
const SetUpGroup = lazy(() => import('../pages/Admin/Group/SetupGroup.js'));
const OpenTradeGroup = lazy(() => import('../pages/Admin/Group/OpenTradeGroup.js'));
const Withdraw = lazy(() => import('../pages/Admin/withdraw/Withdraw.js'));
const Bonus = lazy(() => import('../pages/Admin/bonus/Bonus.js'));
const Setup = lazy(() => import('../pages/Admin/setup/Setup.js'));
const ProfileAdmin = lazy(() => import('../pages/Admin/profile/Profile.js'));
const BuyPackageHistory = lazy(() => import('../pages/Admin/package/packageHistory.js'));
const Deposit = lazy(() => import('../pages/Admin/deposit/Deposit.js'));
const DepositTwo = lazy(() => import('../pages/Admin/deposit/DepositTwo.js'));
const WithdrawTwo = lazy(() => import('../pages/Admin/withdraw/WithdrawTwo.js'));
const LiveChart = lazy(() => import('../pages/Admin/chart/Chart.js'));
const BotRules = lazy(() => import('../pages/Admin/botpackage/BotRules.js'));
const BotData = lazy(() => import('../pages/Admin/botpackage/BotData.js'));





class Web extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>

        <Switch>
          {/* main menu */}
          {/* main menu dashboard*/}
          {/* <RouteGuard exact path="/dashboard" component={Dashboard} /> */}
          <RouteGuard exact path="/dashboard" component={Dashboard} />
          {/* Register */}
          <PublicGuard exact path="/register" component={Register} />
          <RouteGuard exact path="/test" component={Test} />

          <PublicGuard exact path="/login" component={Login} />
          <RouteGuard exact path="/terms/conditon" component={TermsCondition} />
          <RouteGuard exact path="/screening" component={Screening} />
          <RouteGuard exact path="/package" component={BotPackage} />
          <RouteGuard exact path="/setup" component={BotSetup} />
          <RouteGuard exact path="/transaction/dex-trade" component={TransactionDexTrade} />
          <RouteGuard exact path="/transaction/referral" component={TransactionReferral} />
          <RouteGuard exact path="/transaction/history" component={TransactionHistory} />
          <RouteGuard exact path="/profile" component={Profile} />
          <RouteGuard exact path="/password" component={Password} />
          <RouteGuard exact path="/wallet" component={Wallet} />
          <RouteGuard exact path="/wallet/history" component={WalletHistory} />
          {/* <PublicGuard path="/login" component={Login} /> */}
          {/* <PublicGuard path="/notfound" component={notfound} /> */} 


          <PublicAdmin exact path="/My4RBI4DM/login" component={LoginAdmin} />
          <AdminGuard exact path="/My4RBI4DM/dashboard" component={DashboardAdmin} />
          <AdminGuard exact path="/My4RBI4DM/user" component={ManageUserAdmin} />
          <AdminGuard exact path="/My4RBI4DM/swap/history" component={SwapHistory} />
          <AdminGuard exact path="/My4RBI4DM/send/history" component={SendHistory} />
          
          <AdminGuard exact path="/My4RBI4DM/bot/package" component={BotPackageAdmin} />
          <AdminGuard exact path="/My4RBI4DM/buy/package/history" component={BuyPackageHistory} />
          <AdminGuard exact path="/My4RBI4DM/voucher" component={VoucherAdmin} />
          <AdminGuard exact path="/My4RBI4DM/manage-token" component={ManageTokenAdmin} />
          <AdminGuard exact path="/My4RBI4DM/open-trade" component={OpenTrade} />
              {/*  Penambahan trade new*/}
          <AdminGuard exact path="/My4RBI4DM/setup/group" component={SetUpGroup} />
          <AdminGuard exact path="/My4RBI4DM/trade/group" component={OpenTradeGroup} />
          <AdminGuard exact path="/My4RBI4DM/withdraw" component={Withdraw} />
          <AdminGuard exact path="/My4RBI4DM/bonus" component={Bonus} />
          <AdminGuard exact path="/My4RBI4DM/setup/variable" component={Setup} />
          <AdminGuard exact path="/My4RBI4DM/profile" component={ProfileAdmin} />
          <AdminGuard exact path="/My4RBI4DM/deposit" component={Deposit} />
          <AdminGuard exact path="/My4RBI4DM/two/deposit" component={DepositTwo} />
          <AdminGuard exact path="/My4RBI4DM/two/withdraw" component={WithdrawTwo} />
          <AdminGuard exact path="/My4RBI4DM/live/chart" component={LiveChart} />
          <AdminGuard exact path="/My4RBI4DM/bot/rules" component={BotRules} />
          <AdminGuard exact path="/My4RBI4DM/bot/data" component={BotData} />
          <Redirect to="/dashboard" />
          <Redirect to="/notfound" />

     





        </Switch>
      </Suspense>
    );
  }
}

export default Web;