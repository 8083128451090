import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom";
import './footer.css';
class FooterUser extends Component {
  render() {
    return (
      <>
        <div className='container footer-mainn'>

          <div className='d-flex justify-content-between '>

            <NavLink  to="/dashboard" activeClassName="active-user">
              <div className="sub-menu text-center align-middle">
                <span className="menu-icon icon-md  text-white">
                  <i className="mdi mdi-chart-bar"></i>
                </span>
                <span className="menu-title text-white">Home</span>
              </div>
            </NavLink>



            <NavLink activeClassName="active-user" to="/setup">
              <div className="sub-menu text-center align-middle">
                <span className="menu-icon icon-md pr-3 text-white">
                  <i className="mdi mdi-clipboard-text"></i>
                </span>
                <span className="menu-title text-white">Bot Setup</span>
              </div>
            </NavLink>



            <NavLink activeClassName="active-user" to="/package">
              <div className="sub-menu text-center align-middle">
                <span className="menu-icon icon-md pr-3 text-white">
                  <i className="mdi mdi-cart"></i>
                </span>
                <span className="menu-title text-white">Bot Type</span>
              </div>
            </NavLink>


            <NavLink activeClassName="active-user" to="/transaction/dex-trade">
              <div className="sub-menu text-center align-middle">
                <span className="menu-icon icon-md pr-3 text-white">
                  <i className="mdi mdi-chart-line
"></i>
                </span>
                <span className="menu-title text-white">DEX Trade</span>
              </div>
            </NavLink>


            <NavLink activeClassName="active-user" to="/transaction/history">
              <div className="sub-menu text-center align-middle">
                <span className="menu-icon icon-md pr-3 text-white">
                  <i className="mdi mdi-file-multiple
"></i>
                </span>
                <span className="menu-title text-white">Transaction   </span>
              </div>
            </NavLink>
          </div>


        </div>







        {/* <footer className="footer">
        <div className="container-fluid">
          <div className="d-sm-flex justify-content-center justify-content-sm-between py-2 w-100">
            <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © <a href="https://memberlogin-nubi.backoffice-id.com" target="_blank" rel="noopener noreferrer">KYLE </a>2023</span>
          </div>
        </div>
      </footer> */}
      </>

    );
  }
}

export default FooterUser;