import authHeader from "../../auth-header";


class profile {

    me() {
        return authHeader().post(process.env.REACT_APP_BASE_URL + '/me')
    }


    updateProfile(image, fullname, phone, email) {
        return authHeader().post(process.env.REACT_APP_BASE_URL + '/update/profile', {
            image, fullname, phone, email
        })
            .then(response => {
                return response;
            });
    }

    updateProfileTxHash(image, fullname, phone, email, txhash) {
        return authHeader().post(process.env.REACT_APP_BASE_URL + '/update/profile/txhash', {
            image, fullname, phone, email, txhash
        })
            .then(response => {
                return response;
            });
    }


    variableUpdateProfile() {
        return authHeader().post(process.env.REACT_APP_BASE_URL + '/profile/variable')

    }




}

export default new profile();
